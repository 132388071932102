<template>
  <div class="wrapper">
    <div class="data-deletion">
      <div class="data-deletion__body">
        <h1>Инструкции по удалению данных</h1>
        <p>
          Все ваши данные находятся в безопасности, без вашего ведома ни в каких
          рекламных целях не используются. В случае если вы хотите удалить ваши
          данные (данные аккаунт, пройденных тестов и другие персональные
          данные) которые распологаются на сайте psyhotest.соm - обращайтесь на
          почту hello@psyhotest.com. В названии имейла укажите "Data Deletion
          Request".
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.data-deletion {
  & p {
    font-size: 16px;
  }

  & * {
    text-align: left;
  }

  &__body {
    width: 100%;
    max-width: 1000px;
    margin: 0px auto;

    padding: 25px 25px;

    @media screen and (max-width: 700px) {
      padding: 25px 15px;
    }
  }
}
</style>